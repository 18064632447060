/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

$(async function () {

	// Slideshow
	$('.member-slider.swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		var mySwiper = new Swiper(this, {
			grabCursor: true,
			slidesPerView: 8,
			spaceBetween: 60,
			loop: true,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			breakpoints: {
				1680: {
					slidesPerView: 7
				},
				1440: {
					slidesPerView: 6
				},
				1280: {
					slidesPerView: 5
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 60
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 40
				},
				432: {
					slidesPerView: 2,
					spaceBetween: 20
				}
			}
		})
	});

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler')
		var responsiveNav = $('.responsive-nav');

		$(document.body).toggleClass('menu-open');
		responsiveNav.toggleClass('-collapsed');
		toggler.toggleClass('is-active');
	}

	// file uploades
	var inputs = document.querySelectorAll('.fileupload input');
	Array.prototype.forEach.call(inputs, function (input) {
		var label = input.nextElementSibling,
			labelVal = label.innerHTML;

		input.addEventListener('change', function (e) {
			var fileName = '';
			if (this.files && this.files.length > 1)
				fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
			else
				fileName = e.target.value.split('\\').pop();

			if (fileName)
				$(label).attr('data-caption', fileName);
			else
				$(label).attr('data-caption', labelVal);
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Jump to submitted form
	$('.layout__main:has(form)').each(function () {
		var $this = $(this),
			form = $this.find('form'),
			anchor = $this.find('.anchor').attr('name');

		if (anchor) {
			form.attr('action', form.attr('action') + '#' + anchor);
		}
	});
});